





































































import Vue from "vue";
import firebase from "@/plugins/firebase";
import { Course } from "@/types";
import RegisteredUsers from "./RegisteredUsers.vue";

export default Vue.extend({
  components: {
    RegisteredUsers
  },
  data() {
    return {
      search: "",
      loading: false,
      headers: [
        {
          text: "Ad",
          align: "start",
          filterable: true,
          value: "name"
        },
        { text: "Kayıtlı", value: "registeredUserCount" },
        { text: "Tamamlayan", value: "completedUserCount" },
        { text: "", value: "actions", sortable: false, width: "50px" }
      ],
      items: [] as Course[],
      selectedItem: {} as Course,
      dialog: false,
      dialogContent: "",
      allUids: [] as string[]
    };
  },

  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },

    business() {
      return this.$store.getters["auth/business"];
    },

    selectedItemProps(): Course | {} {
      if (this.dialogContent === "RegisteredUsers") {
        return {
          course: this.selectedItem,
          businessRegUids: this.allUids
        };
      } else {
        return {};
      }
    }
  },

  methods: {
    async getAllUids() {
      try {
        this.allUids = [];

        // Tüm kullanıcı ID'lerini al
        const userInvQ = await firebase
          .firestore()
          .collection(`businessUserInvitations`)
          .where("businessRegId", "==", this.business.regId)
          .where("isAccepted", "==", true)
          .orderBy("dateCreated", "desc")
          .get();

        userInvQ.forEach(inv => {
          this.allUids.push(inv.data().uid);
        });
      } catch (err) {
        const e = err as firebase.firestore.FirestoreError;
        console.error(`Hata: Kullanıcı id'leri alınamadı. ${e.message}`);
      }
    },

    async getUserCounts(courseId: string) {
      let registeredUserCount = 0;
      let completedUserCount = 0;

      try {
        // uid'leri tara. Firebase in sorgusunda max. 10 öğeye izin veriyor
        const limit = 10;
        for (let i = 0; i < this.allUids.length / limit; i++) {
          const start = i * limit;
          const finish = start + limit;
          const uids = this.allUids.slice(start, finish);

          // Ders kayıtlarını tara
          const cregQuery = await firebase
            .firestore()
            .collection("businessCourseRegistrations")
            .where("uid", "in", uids)
            .where("courseId", "==", courseId)
            .get();

          cregQuery.forEach(async creg => {
            registeredUserCount = registeredUserCount + 1;

            if (creg.data().progress == 100) {
              completedUserCount = completedUserCount + 1;
            }
          });
        }
      } catch (err) {
        const e = err as firebase.firestore.FirestoreError;
        console.error(`Hata: Kullanıcı sayıları alınamadı. ${e.message}`);
      }

      return {
        registeredUserCount: registeredUserCount,
        completedUserCount: completedUserCount
      };
    },

    async getItems() {
      this.loading = true;
      this.items = [];

      try {
        // Get all courses
        const query = await firebase
          .firestore()
          .collection("courses")
          .where("disabled", "==", false)
          .orderBy("name")
          .get();

        query.forEach(async doc => {
          if (doc.exists) {
            const course = doc.data();
            const newItem: Course = {
              id: doc.id,
              name: course.name,
              code: course.code,
              urlCode: course.urlCode,
              price: course.price,
              disabled: course.disabled,
              creationTime: course.creationTime.toDate()
            };

            if (course.color) newItem.color = course.color;
            if (course.desc) newItem.desc = course.desc;
            if (course.features) newItem.features = course.features;
            if (course.learningOutcomes)
              newItem.learningOutcomes = course.learningOutcomes;
            if (course.modules) newItem.modules = course.modules;
            if (course.previewImg) newItem.previewImg = course.previewImg;
            if (course.rating) newItem.rating = course.rating;
            if (course.updateTime)
              newItem.updateTime = course.updateTime.toDate();

            // Kullanıcı sayılarını al
            const userCounts = await this.getUserCounts(newItem.id);
            newItem.completedUserCount = userCounts.completedUserCount;
            newItem.registeredUserCount = userCounts.registeredUserCount;

            this.items.push(newItem);
          }
        });

        this.loading = false;
      } catch (e) {
        const err = e as firebase.firestore.FirestoreError;

        this.loading = false;
        this.$notify({
          type: "error",
          text: `HATA: Eğitim listesi alınamadı. ${err.code} ${err.message}`
        });
      }
    },

    closeDialog() {
      this.dialog = false;
      this.dialogContent = "";
    },

    openRegisteredUsers(item: Course) {
      this.dialogContent = "RegisteredUsers";
      this.selectedItem = item;
      this.dialog = true;
    },

    open(item: Course) {
      this.dialogContent = "RegisteredUsers";
      this.selectedItem = item;
      this.dialog = true;

      this.$router.push({
        name: "supervisor-course-home",
        params: { courseUrlCode: item.urlCode }
      });
    }
  },

  // watch: {
  //   business: async function() {
  //     await this.getAllUids();
  //     await this.getItems();
  //   }
  // },

  async mounted() {
    await this.getAllUids();
    await this.getItems();

    this.$watch("business", async function() {
      await this.getItems();
    });
  }
});
